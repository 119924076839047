@import "./Colors.scss";

.navbar {
    padding: 10px;
    padding-left: 40px;
    padding-right: 40px;

    .navbar-brand {
        img {
            width: 70px;
        }
    }

    .nav-link {
        &.active {
            border-bottom: 3px solid $secondaryColor;
        }
    }
}

.admin-navbar {
    background-color: $secondaryColor;
    z-index: 8;
    position: relative;

    .btn-menu {
        i {
            color: white;
        }
    }
}

.side-nav {
    position: fixed;
    width: 250px;
    background-color: white;
    height: 100vh;
    padding-top: 130px;
    z-index: 5;
    overflow-y: auto;

    .body-side-nav {
        ul.main-menu {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                a {
                    padding: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                    display: block;
                }

                border-bottom: 1.5px solid rgba(0,0,0, 0.1);
            }

            ul {
                li {
                    border-bottom: none;
                }
            }
        }
    }
}

.side-menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 999999999999;

    .backdrop {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999999999999;
        background-color: rgba(0,0,0,0.4);
        width: 100%;
        height: 100%;
        cursor: pointer;
        animation-name: show-in;
        animation-duration: 0.4s;
        animation-iteration-count: 1;
    }

    @keyframes show-in {
       0% {
           opacity: 0;
       }

       100% {
           opacity: 1;
       }
    }

    .side-menu-wrapper {
        position: fixed;
        right: 0;
        top: 0;
        width: 300px;
        z-index: 9999999999999 !important;
        background-color: white;
        padding: 20px;
        padding-top: 80px;
        height: 100%;
        animation-name: show-slide;
        animation-duration: 0.4s;
        animation-iteration-count: 1;

        @keyframes show-slide {
             0% {
                 transform: translate(100%,0);
             }

             100% {
                 transform: translate(0,0);
             }
        }

        .btn-close {
            position: absolute;
            right: 10px;
            top: 10px;
        }

        .menu {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin-top: 10px;

                &:first {
                    margin-top: 0 !important;
                }
                a {
                    padding: 10px;
                    transition: 0.3s;
                    border-radius: 10px;
                    display: block;
                    text-decoration: none;
                    color: black;

                    &:hover {
                        background-color: $lightGrey;
                    }
                }
            }
        }
    }
}


@media screen and (max-width:800px){
    .navbar {
    .navbar-brand {
        img {
            width: 50px;
        }
    }
}
}