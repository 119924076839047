@import "./Colors.scss";


.standar-container {
    padding: 40px;
    background-image: url(../../res/backgrounds/paper-red.png);
    background-repeat: repeat;
    
        color: white;

        hr {
            background-color: white;
            opacity: 0.5;
        }
}


.consent-screen-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 9999999999;
    display: flex;
    justify-content: center;
    align-items: center;


    .consent-screen {
        padding: 20px;
    }
}

.join-component {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 60px;
    background-color:#feb104;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    cursor: pointer;
    
    p {
        color: black;
        font-weight: bold;
    }
}

.text-secondary {
    color: $secondaryColor !important;
}

.footer {
    background-color: $secondaryColor;
    color: white;

    .btn-section {
        padding-left: 40px;
        padding-right: 40px;
        background-color: $primaryColor;
        
    }

    .footer-body {
    padding: 40px;

    a {
        color: black;
    }
    }

    .footer-footer {
        background-color: darken($color: $secondaryColor, $amount: 10);
        padding: 20px;
    }

    a {
        color: white;
    }
}

.carousel {
    min-height: 450px;
    background-color: $lightGrey;
    max-height: 450px;
    overflow: hidden;
    background-color: $secondaryColor;
}

.carousel-component {
    min-height: 450px;
}


.video-item {
    min-width: 300px;
    max-width: 300px;
    border-radius:0px;
    overflow: hidden;
    cursor: pointer;

    .video-thumbnail-wrapper {
        max-height: 200px;
        overflow: hidden;
    }

    .video-thumbnail {
        background-color: #fbf5f5;
        opacity: 1;
        min-height: 200px;
        background-size: cover;
        transition: 0.4s !important;

        &:hover {
            transform: scale(1.2);
        }
    }

    .video-item-body {
        padding: 20px;
        background-color: white;
        color: black;
    }
}

.video-item-loading {
    min-width: 300px;
    border-radius: 0px;
    overflow: hidden;
    cursor: pointer;

    .video-thumbnail {
        background-color: #fbf5f5;
        opacity: 1;
        min-height: 200px;
        animation-name: beating-video-item;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
    }

    @keyframes beating-video-item {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0.5;
        }

        100% {
            opacity: 1;
        }
    }

    .video-item-body {
        padding: 20px;
        background-color: white;

        h5 {
            background-color: $lightGrey;
            height: 30px;
            
        }
    }
}


.login-page {
    .login-wrapper {
        min-height: 100vh;
        background-color: darken($color: $darkGrey, $amount: 10);
       

        .login-panel {
            min-width: 450px;
            background-color: white;
            padding: 40px;
            border-radius: 10px;
            color: black;
        }
    }
}

.sign-up {

    .plan-item {
        cursor: pointer;
        transition: 0.3s;

        &.active {
            background-color: $secondaryColor;
            color: white;
        }
    }

    .header-sign-up {
        font-weight: normal;
    }
}



.auth-admin {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondaryColor;


    .auth-admin-panel {
        border-radius: 10px;
        padding: 40px;
        width: 400px;
        background-color: white;
        color: black;

        .header {
            font-weight: normal;
        }
    }
}


.admin-wrapper {
    min-height: 100vh;
    background-color: $lightGrey;

    .admin-container {
        padding: 40px;
        padding-left: 290px;
    }

}

.special-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    color: black;
}


.composed-card {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-left: 40px;
    margin-right: 40px;
    .card-header{
        padding: 0;
    }

    .card-body {
        padding: 20px;
    }
}

.admin-section-indicator {
    padding: 20px;
    background-color: white;
    z-index: 8;
    position: relative;
}

.thumbnail-video{
    position: relative;
    max-width: 300px;
    min-height: 200px;
    background-color: $mediumGrey;
    cursor: pointer;

    .custom-file {
        cursor: pointer !important;
        position: absolute !important;
        top:0;
        left: 0;
        height: 100% !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        background-color: transparent !important; 
        cursor: pointer;
    
        .custom-file-input {
            height: 100%;
            border: none !important;
            background-color: transparent !important;
            cursor: pointer;
            outline: none !important;
        box-shadow: none !important;
        }
        .custom-file-label {
            height:100% !important;
            border: none !important;
            background-color: transparent !important;
            cursor: pointer;
            outline: none !important;
        box-shadow: none !important;
    
            &:after {
                content:"";
                background-color: transparent;
                border: none !important;
                cursor: pointer;
                outline: none !important;
        box-shadow: none !important;
            }
        }
    }
}



.modal-wrapper {
    background-color:rgba(0,0,0,0.8);
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    z-index: 99999 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-close {
        position: absolute;
        right: 15px;
        top:15px;
        background-color:white;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .add-product {

        .image-select {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: $lightGrey;
            min-height: 150px;
            min-width: 150px;
            border-radius: 10px;
        
            i {
                color:$darkGrey;
                font-size: 50px;
            }
        
            .custom-file {
                cursor: pointer;
                position: absolute !important;
                top:0;
                left: 0;
                height: 100% !important;
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
                background-color: transparent !important; 
                cursor: pointer;
        
                .custom-file-input {
                    height: 100%;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
                }
                .custom-file-label {
                    height:100% !important;
                    border: none !important;
                    background-color: transparent !important;
                    cursor: pointer;
                    outline: none !important;
                box-shadow: none !important;
        
                    &:after {
                        content:"";
                        background-color: transparent;
                        border: none !important;
                        cursor: pointer;
                        outline: none !important;
                box-shadow: none !important;
                    }
                }
            }
        }
        
        .categories {
            .category-element {
                background-color: white;
                padding: 10px;
                border-radius: 10px;
        
                .image {
                    width:80px;
                    height: 80px;
                    border-radius: 10px;
                }
            }
        
            .btn-custom-2 {
                max-width: 200px;
                min-width: 200px;
            }
        }
        
        .items {
            .input-group-2 {
                border-radius: 30px !important;
                overflow: hidden;
                max-width: 200px;
                .form-control {
                    border:none !important;
                    padding-left: 15px;
                }
        
                .input-group-append {
        
                    .input-group-text{
                    background-color: white;
                    border: none !important;
                    }
                }
        
                
            }
        
            .filter-text {
                width: auto;
                display: block;
                min-width: 150px;
                text-align: right;
            }
        
            .btn-custom-2 {
                max-width: 200px;
                min-width: 200px;
            }
        
            .custom-select-2 {
                border: none !important;
                border-radius: 30px;
                max-width: 200px;
            }
        
            .item-element {
                background-color: white;
                padding: 10px;
                border-radius: 10px;
        
                .image {
                    width:80px;
                    height: 80px;
                    border-radius: 10px;
                }
            }
        
            .btn-custom-2 {
                max-width: 200px;
                min-width: 200px;
            }
        
            .extra-item-editable {
                .form-control {
                    width: auto;
                }
        
                .input-group {
                    width: auto;
                }
            }
        
        
        }
        
        
        .border-button {
            padding: 10px;
            cursor: pointer;
            color:$mediumGrey;
            border-bottom: 2px solid $lightGrey;
            padding-left: 20px;
            padding-right: 20px;
            transition: 0.3s;
            min-width: 120px;
        
            &.active {
                color:$primaryColor;
                border-bottom: 2px solid $primaryColor;
            }
        }
        
        
        .extra-item-editable {
            background-color: rgb(238,238,238);
            padding: 15px;
            border-radius: 10px;
        
            .form-group{
                margin-bottom: 0;
            .form-control {
                background-color: transparent;
            
            }
        }
        }
        
        
        
        
        }

        .backdrop {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            cursor: pointer;
        }
        

    .modal-content {
        width: auto;
        max-width: 80%;
        background-color: white;
        border-radius: 10px;
        padding:40px;
        position: relative;
        min-height: 600px;
        max-height: 90% !important;
        overflow: auto !important;

        

     
            
        
    }

}



.image-add {
    width: 464.9px;
    height: 200px;
    border: 2px dashed black;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    .custom-file {
        cursor: pointer !important;
        position: absolute !important;
        top:0;
        left: 0;
        height: 100% !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        background-color: transparent !important; 
        cursor: pointer;
    
        .custom-file-input {
            height: 100%;
            border: none !important;
            background-color: transparent !important;
            cursor: pointer;
            outline: none !important;
        box-shadow: none !important;
        }
        .custom-file-label {
            height:100% !important;
            border: none !important;
            background-color: transparent !important;
            cursor: pointer;
            outline: none !important;
        box-shadow: none !important;
    
            &:after {
                content:"";
                background-color: transparent;
                border: none !important;
                cursor: pointer;
                outline: none !important;
        box-shadow: none !important;
            }
        }
    }
}

.photo-element {
    width: 464.9px;
    height: 200px;
    border-radius: 3px;
    position: relative;
    border:1.5px solid $lightGrey;

    .btn-clear-img {
        position: absolute;
        bottom: 100%;
        left: 100%;
        background-color: $primaryColor;
        color: white;
        border: none !important;
        outline: none !important;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(-50%,50%);
    }

    .progress {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translate(-50%,0);
        z-index: 2;
        width: 70%;
        .progress-bar {
            background-color: $primaryColor
        }
    }

    .custom-file {
        position: absolute;
        top:0;
        left: 0;
        height: 100px !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        background-color: transparent !important; 
        cursor: pointer;
    
        .custom-file-input {
            height: 100%;
            border: none !important;
            background-color: transparent !important;
            cursor: pointer;
            outline: none !important;
        box-shadow: none !important;
        }
        .custom-file-label {
            height:100% !important;
            border: none !important;
            background-color: transparent !important;
            cursor: pointer;
            outline: none !important;
        box-shadow: none !important;
    
            &:after {
                content:"";
                background-color: transparent;
                border: none !important;
                cursor: pointer;
                outline: none !important;
        box-shadow: none !important;
            }
        }
    }
}

.photo-element-add {
    position: relative;
    height: 464.9px;
    width: 200px;
    border: 1.5px dashed $primaryColor;
    border-radius: 3px;
    cursor: pointer;

     i{
         font-size: 35px;
         color: $darkGrey;
     }

     .custom-file {
        position: absolute;
        top:0;
        left: 0;
        height: 100px !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        background-color: transparent !important; 
        cursor: pointer;
    
        .custom-file-input {
            height: 100%;
            border: none !important;
            background-color: transparent !important;
            cursor: pointer;
            outline: none !important;
        box-shadow: none !important;
        }
        .custom-file-label {
            height:100% !important;
            border: none !important;
            background-color: transparent !important;
            cursor: pointer;
            outline: none !important;
        box-shadow: none !important;
    
            &:after {
                content:"";
                background-color: transparent;
                border: none !important;
                cursor: pointer;
                outline: none !important;
        box-shadow: none !important;
            }
        }
    }
}


@media screen and (max-width:800px){
     
    .footer {
        .btn-section {
            .btn-group {
                display: flex !important;
                flex-wrap: wrap !important;
            }
        }
    }

    .carousel {
        min-height: auto !important;
        background-color: $lightGrey;
        max-height: auto !important;
        overflow: hidden;
    }

    .sign-up {
        .sign-up-image {
            width:  100% !important;
        }

        .row {
            .col-sm-6 {
                padding: 0 !important;
            }


        }
    }

    .standar-container {
        padding: 20px;
        background-color: #861115;
        color: white;
    }

    .composed-card {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .btn {
        &.ml-2 {
            margin-left:  0 !important;
        }

        &.mr-2 {
            margin-right:  0 !important;
        }
    }

    .login-page {
        .login-wrapper {
            min-height: 100vh;
            background-color: darken($color: $darkGrey, $amount: 10);
           
    
            .login-panel {
                min-width: 100%;
                max-width: 100%;
                background-color: white;
                padding: 20px;
                border-radius: 10px;
                color: black;
            }
        }
    }
}

